<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header" v-if="!this.id">Создание шаблона</div>
      <div class="table-header" v-else>Редактирование шаблона: {{ this.letterTemplateInfo.name }}</div>
      <div class="btn-group">
        <router-link class="btn btn-secondary" to="/list-letter-template">Назад</router-link>
        <a class="btn btn-success" @click="save">Сохранить</a>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2">
        <div class="form-group">
          <label class="form-label">Название шаблона</label>
          <input type="text" class="form-control" placeholder="Название шаблона" v-model="name">
        </div>
      </div>
    </div>
    <EmailEditor style="height: 1000px"
                 ref="emailEditor"
                 :options="options"
                 :locale="locale"
                 v-on:load="editorLoaded"
                 v-on:ready="editorReady"
    />
  </section>
</template>

<script>
import {EmailEditor} from 'vue-email-editor';
import {mapGetters} from "vuex";

export default {
  name: 'designer-letter-template',
  props: ['id'],
  components: {
    EmailEditor
  },
  data: function () {
    return {
      locale: 'ru',
      name: '',
      jsonData: '',
      html: null,
      options: {},
    }
  },
  computed: {
    ...mapGetters(['letterTemplateInfo']),
  },
  async mounted() {
    if (this.id) {
      this.getLetterTemplate();
    }
  },
  methods: {
    getLetterTemplate: function () {
      this.$store.dispatch('getLetterTemplate', this.id).then(() => {
        this.name = this.letterTemplateInfo.name;
        this.jsonData = this.letterTemplateInfo.jsonData;
      });
    },
    editorLoaded() {

    },
    editorReady() {
      if (this.id) {
        this.$refs.emailEditor.editor.loadDesign(JSON.parse(this.jsonData));
      }
    },
    save() {
      this.$refs.emailEditor.editor.saveDesign((design) => {
        this.jsonData = design;
      });
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.html = data;
        if (this.id) {
          this.$store.dispatch('editLetterTemplate', {
            'id': this.id,
            'name': this.name,
            'json': this.jsonData,
            'html': this.html,
          });
        } else {
          this.$store.dispatch('saveLetterTemplate', {
            'name': this.name,
            'json': this.jsonData,
            'html': this.html,
          });
        }
      });
    },
  }
}
</script>